import { useState, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./style/LandindPages.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import Base from "./Components/Layout/Base";
import Presentation from "./Components/Elements/Meeting/Presentation";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Signup from "./Components/Elements/Signup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./Components/Elements/Login";
import PrivateRoute from "./Components/Elements/PrivateRoute";
import Preview from "./Components/Elements/Preview";
// import Profile from "./Components/Elements/User/Profile";
import Profile from "./Components/Elements/Profile/Profile";
import Presentationreport from "./Components/Elements/Meeting/Presentationreport";
import Contract from "./Components/Elements/Contract/Contract";
import Enterprises from "./Components/Elements/Enterprises/Enterprises";
import NewEnterprises from "./Components/Elements/Enterprises/NewEnterprises";
import Team from "./Components/Elements/Team/Team";
import Invities from "./Components/Elements/Invities/Invities";
// import ObjectiveTabs from "./Components/Elements/Objectives/ObjectiveTabs.jsx";
import UpdateContract from "./Components/Elements/Contract/UpdateContract";
import UpdateEntreprises from "./Components/Elements/Enterprises/UpdateEntreprises";
import UpdateTeam from "./Components/Elements/Team/UpdateTeam";
import Users from "./Components/Elements/User/Users";
import ContractLinkEntreprises from "./Components/Elements/LinkPages/ContractLinkEntreprises";
import ContractToTeam from "./Components/Elements/LinkPages/ContractToTeam";
import EntreprisesToTeam from "./Components/Elements/LinkPages/EntreprisesToTeam";
import global_en from "./translations/en/global.json";
import global_fr from "./translations/fr/global.json";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import CopyContract from "./Components/Elements/Contract/CopyContract";
import CopyClosedContract from "./Components/Elements/Contract/CopyClosedContract";
import ReadContract from "./Components/Elements/Contract/ReadContract";
import UpdateUser from "./Components/Elements/User/UpdateUser";
import ContractToUser from "./Components/Elements/LinkPages/ContractToUser";
import EnterprisesToUser from "./Components/Elements/LinkPages/EnterprisesToUser";
import MeetingTabs from "./Components/Elements/Meeting/MeetingTabs";
import ValidateMeeting from "./Components/Elements/Meeting/ValidateMeeting";
import UpdateMeeting from "./Components/Elements/Meeting/UpdateMeeting";
import MeetingCopy from "./Components/Elements/Meeting/MeetingCopy";
import Play from "./Components/Elements/Meeting/Play";
import Invite from "./Components/Elements/Meeting/Invite";
import ViewMeeting from "./Components/Elements/Meeting/ViewMeeting";
import Draft from "./Components/Elements/Meeting/Draft";
import PlayMeeting from "./Components/Elements/Meeting/PlayMeeting/PlayMeeting";
import { CounterContextProvider } from "./Components/Elements/Meeting/context/CounterContext";
import ViewEndMeeting from "./Components/Elements/Meeting/ViewEndMeeting";
import ParticipantToAction from "./Components/Elements/Invities/ParticipantToAction/ParticipantToAction";
import UpdateParticipant from "./Components/Elements/Invities/ParticipantToAction/UpdateParticipant";
import StepDetails from "./Components/Elements/Meeting/StepDetails";
import UpdatePassword from "./Components/Elements/User/UpdatePassword";
import axios from "axios";
import { API_BASE_URL } from "./Components/Apicongfig";
import NewMeeting from "./Components/Elements/Meeting/NewMeeting";
import Report from "./Components/Elements/Meeting/Report";
import CustomerSupport from "./Components/Elements/CustomerSupport";
import CompletedInvite from "./Components/Elements/Meeting/CompletedMeeting/CompletedInvite";
import DestinationToMeetings from "./Components/Elements/Invities/DestinationToMeeting/DestinationToMeetings";
import BasePage from "./Components/Layout/BasePage";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Privacypolicy from "./Pages/Privacypolicy";
import Termsandconditions from "./Pages/Termsandconditions";
import Contactus from "./Pages/Contactus";
import Planandpricing from "./Pages/Planandpricing";
import AOS from "aos";
import "aos/dist/aos.css";
import ProfileInvitePage from "./Components/Elements/Profile/ProfileInvitePage";

i18next.init({
  interpolation: { escapevalue: false },
  lng: "fr",
  resources: {
    en: {
      global: global_en,
    },
    fr: {
      global: global_fr, // Corrected key name
    },
  },
});

function App() {
  const navigate = useNavigate();
  const [isSignedIn, setIsSignedIn] = useState(() => {
    return localStorage.getItem("isSignedIn") === "true";
  });

  useEffect(() => {
    if (window.location.href === "https://www.tektime.io/") {
      window.location.replace("https://tektime.io");
    }
  }, []);
  
  const [removeLogo, setRemoveLogo] = useState(false);

  const signin = () => {
    setIsSignedIn(true);
    setRemoveLogo(true);
  };

  const signout = () => {
    setIsSignedIn(false);
    setRemoveLogo(false);
    localStorage.removeItem("isSignedIn");
    // localStorage.removeItem("access_token");
    // localStorage.removeItem("refresh_token");
    // localStorage.removeItem("token_expiration_time");
    sessionStorage.clear();
    navigate("/");
  };

  useEffect(() => {
    localStorage.setItem("isSignedIn", isSignedIn.toString());
  }, [isSignedIn]);

  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  function refreshAccessToken() {
    const refreshToken = localStorage.getItem("refresh_token");

    if (!refreshToken) {
      console.error("No refresh token available");
      return;
    }
    const userid = localStorage.getItem("user_id");

    axios
      .post(
        `${API_BASE_URL}/auth/refresh`,
        {
          // refresh_token: refreshToken,
          user_id: userid,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("Refresh API Response:", response.data);
        if (response) {
          const newAccessToken = response.data?.access_token;
          const newExpiresIn = response.data?.expires_in; // e.g., 3598 seconds

          // Update the stored tokens and expiration time
          sessionStorage.setItem("access_token", newAccessToken);
          localStorage.setItem("access_token", newAccessToken);

          const newExpirationTime = Date.now() + newExpiresIn * 1000;
          sessionStorage.setItem("token_expiration_time", newExpirationTime);
          localStorage.setItem("token_expiration_time", newExpirationTime);

          // Set a new timeout to refresh the access token again
          setTimeout(refreshAccessToken, (newExpiresIn - 60) * 1000); // Refresh 1 minute before expiration
        }
      })
      .catch((error) => {
        console.error("Refresh API Error:", error);
        // Optionally handle token refresh errors (e.g., log out user)
        // If the refresh token is invalid (e.g., 401 Unauthorized), handle re-authentication
        // if (error.response && error.response.status === 401) {
        console.error(
          "Refresh token is invalid or expired. Redirecting to login."
        );
        handleInvalidToken();
        // }
      });
  }

  function handleInvalidToken() {
    sessionStorage.removeItem("access_token");
    localStorage.removeItem("access_token");
    sessionStorage.removeItem("refresh_token");
    localStorage.removeItem("refresh_token");
    sessionStorage.removeItem("token_expiration_time");
    localStorage.removeItem("token_expiration_time");
  }

  // Function to check token expiration and refresh
  function checkTokenExpiration() {
    const tokenExpirationTime =
      sessionStorage.getItem("token_expiration_time") ||
      localStorage.getItem("token_expiration_time");

    console.log("Checking token expiration", tokenExpirationTime);
    if (!tokenExpirationTime) return;

    const currentTime = Date.now();
    if (currentTime > tokenExpirationTime) {
      console.log("Access token expired. Refreshing...");
      sessionStorage.removeItem("access_token");
      localStorage.removeItem("access_token");
      sessionStorage.removeItem("token_expiration_time");
      localStorage.removeItem("token_expiration_time");
      refreshAccessToken();
    } else {
      console.log("not expired now!");
    }
  }

  // Set an interval to check token expiration every minute
  setInterval(checkTokenExpiration, 60 * 1000);

  return (
    <div>
      <I18nextProvider i18n={i18next}>
        <ToastContainer
          style={{
            position: "abosolute",
            zIndex: "101",
          }}
        />
        <Routes>
          <Route path="/login" element={<Login onLogin={signin} />} />
          <Route path="/signup" element={<Signup />} />
          <Route
            element={
              <Base
                isAuthenticated={isSignedIn}
                onLogout={signout}
                onLogin={signin}
                onRemove={removeLogo}
              />
            }
          >
            {sessionStorage.getItem("type") === "MasterAdmin" &&
              sessionStorage.getItem("type") != "SuperAdmin" &&
              sessionStorage.getItem("type") != "Admin" && (
                <Route
                  path="/contract"
                  element={
                    <PrivateRoute isSignedIn={isSignedIn}>
                      <Contract onLogout={signout} />
                    </PrivateRoute>
                  }
                />
              )}
            <Route
              path="/CopyContract/:id"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <CopyContract onLogout={signout} />
                </PrivateRoute>
              }
            />
            <Route
              path="/CopyClosedContract/:id"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <CopyClosedContract onLogout={signout} />
                </PrivateRoute>
              }
            />
            <Route
              path="/ModifierContract/:id"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <UpdateContract onLogout={signout} />
                </PrivateRoute>
              }
            />
            <Route
              path="/ContractLinkEnterprises/:id"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <ContractLinkEntreprises onLogout={signout} />
                </PrivateRoute>
              }
            />
            <Route
              path="/readContract/:id"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <ReadContract onLogout={signout} />
                </PrivateRoute>
              }
            />
            <Route
              path="/ContractToTeam/:id"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <ContractToTeam onLogout={signout} />
                </PrivateRoute>
              }
            />
            <Route
              path="/ContractToUser/:id"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <ContractToUser onLogout={signout} />
                </PrivateRoute>
              }
            />
            <Route
              path="/EntreprisesToTeam/:id"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <EntreprisesToTeam onLogout={signout} />
                </PrivateRoute>
              }
            />
            <Route
              path="/EntreprisesToUsers/:id"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <EnterprisesToUser onLogout={signout} />
                </PrivateRoute>
              }
            />
            <Route
              path="/Enterprises"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <Enterprises onLogout={signout} />
                </PrivateRoute>
              }
            />
            <Route
              path="/NewEnterprises"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <NewEnterprises onLogout={signout} />
                </PrivateRoute>
              }
            />
            <Route
              path="/ModifierEnterprises/:id"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <UpdateEntreprises onLogout={signout} />
                </PrivateRoute>
              }
            />
            <Route
              path="/Team"
              exact
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <Team onLogout={signout} />
                </PrivateRoute>
              }
            />
            <Route
              path="/ModifierTeam/:id"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <UpdateTeam onLogout={signout} />
                </PrivateRoute>
              }
            />

            <Route
              path="/Users/:id"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <Users onLogout={signout} />
                </PrivateRoute>
              }
            />
            <Route
              path="/ModifierUser/:id"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <UpdateUser onLogout={signout} />
                </PrivateRoute>
              }
            />
            <Route
              path="/Invities"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <Invities onLogout={signout} />
                </PrivateRoute>
              }
            />
            <Route
              path="/invitiesToMeeting/:id"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <DestinationToMeetings onLogout={signout} />
                </PrivateRoute>
              }
            />
            {/* <Route
              path="/objectives"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <ObjectiveTabs onLogout={signout} />
                </PrivateRoute>
              }
            /> */}
            <Route
              path="/participantToAction/:id"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <ParticipantToAction onLogout={signout} />
                </PrivateRoute>
              }
            />

            <Route
              path="/updateParticipant/:id"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <UpdateParticipant onLogout={signout} />
                </PrivateRoute>
              }
            />

            <Route
              path="/meeting"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <MeetingTabs onLogout={signout} />
                </PrivateRoute>
              }
            />

            <Route
              path="/profile"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <Profile onLogout={signout} />
                </PrivateRoute>
              }
            />
            {/* UpdatePassword Route */}
            <Route
              path="/updatepassword"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <UpdatePassword onLogout={signout} />
                </PrivateRoute>
              }
            />
            <Route
              path="/meetingcopy/:id"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <MeetingCopy onLogout={signout} />
                </PrivateRoute>
              }
            />
            <Route
              path="/meeting/new"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <NewMeeting onLogout={signout} />
                </PrivateRoute>
              }
            />
            <Route
              path="/meetingDetail/:id"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <ViewEndMeeting onLogout={signout} />
                </PrivateRoute>
              }
            />
            <Route
              path="/presentation/:id"
              element={<Presentation onLogout={signout} />}
            />
            <Route
              path="/participant/:id"
              element={<UpdateParticipant onLogout={signout} />}
            />

            <Route
              path="/presentation/:meetingId"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <Presentation onLogout={signout} />
                </PrivateRoute>
              }
            />
            <Route
              path="/preview/:id"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <Preview onLogout={signout} />
                </PrivateRoute>
              }
            />
            <Route
              path="/validateMeeting/:id"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  {<ValidateMeeting onLogout={signout} />}
                </PrivateRoute>
              }
            />
            <Route
              path="/updateMeeting/:id"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <UpdateMeeting onLogout={signout} />
                </PrivateRoute>
              }
            />
            <Route
              path="/copyMeeting/:id"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <MeetingCopy onLogout={signout} />
                </PrivateRoute>
              }
            />
            <Route
              path="/play/:id"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <CounterContextProvider>
                    <Play onLogout={signout} />
                  </CounterContextProvider>
                </PrivateRoute>
              }
            />

            <Route
              path="/PlayMeeting/:id"
              element={
                <CounterContextProvider>
                  <PlayMeeting />
                </CounterContextProvider>
              }
            />
            <Route
              path="/view/:id"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <ViewMeeting onLogout={signout} />
                </PrivateRoute>
              }
            />
            <Route
              path="/heroes/:nick_name"
              element={<ProfileInvitePage onLogout={signout} />}
            />
            <Route path="/invite/:id" element={<Invite onLogout={signout} />} />
            <Route
              path="/destination/:unqiue_id/:meeting_id"
              element={<Report />}
              // element={<Presentationreport />}
            />
            <Route
              path="/draft/:id"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <Draft onLogout={signout} />
                </PrivateRoute>
              }
            />
            <Route
              path="/present/invite/:id"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <CompletedInvite onLogout={signout} />
                </PrivateRoute>
              }
            />
            <Route
              path="/customer-support"
              element={
                <PrivateRoute isSignedIn={isSignedIn}>
                  <CustomerSupport onLogout={signout} />
                </PrivateRoute>
              }
            />
          </Route>
        </Routes>
        <Routes>
          <Route path="/step-details/:id" element={<StepDetails />} />
          {/* <Route path="/invite/:id" element={<Invite />} /> */}
          {/* <Route path="/invite" element={<Invite />} /> */}
        </Routes>
        <BasePage>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/privacy-policy" element={<Privacypolicy />} />
            <Route path="/terms&conditions" element={<Termsandconditions />} />
            <Route path="/contactus" element={<Contactus />} />
            <Route path="/pricing" element={<Planandpricing />} />
          </Routes>
        </BasePage>
      </I18nextProvider>
    </div>
  );
}

export default App;
