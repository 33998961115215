import React, { useEffect, useRef, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import {
  FaLinkedin,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaBehance,
  FaDiscord,
  FaDribbble,
  FaGoogle,
  FaLine,
  FaFacebookMessenger,
  FaPinterest,
  FaQq,
  FaReddit,
  FaSkype,
  FaSlack,
  FaSnapchat,
  FaSpotify,
  FaMicrosoft,
  FaTelegram,
  FaTiktok,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
import { API_BASE_URL, Assets_URL } from "../../Apicongfig";
import { useTranslation } from "react-i18next";
import axios from "axios";
import ReactPlayer from "react-player";
import VisibilityMessageModal from "./VisibilityMessageModal";
// import ConfirmationModal from "../../Utils/ConfirmationModal";

const getPlatformIcon = (platform) => {
  switch (platform) {
    case "LinkedIn":
      return (
        <FaLinkedin className="platform-icon" style={{ color: "#0A66C2" }} />
      );
    case "Facebook":
      return (
        <FaFacebook className="platform-icon" style={{ color: "#1877F2" }} />
      );
    case "Twitter":
      return (
        <FaTwitter className="platform-icon" style={{ color: "#1DA1F2" }} />
      );
    case "Whatsapp":
      return (
        <FaWhatsapp className="platform-icon" style={{ color: "green" }} />
      );
    case "Youtube":
      return (
        <FaYoutube className="platform-icon" style={{ color: "red" }} />
      );
    case "Instagram":
      return (
        <FaInstagram className="platform-icon" style={{ color: "#C13584" }} />
      );
    case "Behance":
      return (
        <FaBehance className="platform-icon" style={{ color: "#1769FF" }} />
      );
    case "Discord":
      return (
        <FaDiscord className="platform-icon" style={{ color: "#7289DA" }} />
      );
    case "Dribble":
      return (
        <FaDribbble className="platform-icon" style={{ color: "#EA4C89" }} />
      );
    case "Google My Business":
      return (
        <FaGoogle className="platform-icon" style={{ color: "#4285F4" }} />
      );
    case "Line":
      return <FaLine className="platform-icon" style={{ color: "#00C300" }} />;
    case "Messenger":
      return (
        <FaFacebookMessenger
          className="platform-icon"
          style={{ color: "#0084FF" }}
        />
      );
    case "Pinterest":
      return (
        <FaPinterest className="platform-icon" style={{ color: "#E60023" }} />
      );
    case "QQ":
      return <FaQq className="platform-icon" style={{ color: "#00B2A9" }} />;
    case "Reddit":
      return (
        <FaReddit className="platform-icon" style={{ color: "#FF4500" }} />
      );
    case "Skype":
      return <FaSkype className="platform-icon" style={{ color: "#00AFF0" }} />;
    case "Slack":
      return <FaSlack className="platform-icon" style={{ color: "#4A154B" }} />;
    case "Snapchat":
      return (
        <FaSnapchat className="platform-icon" style={{ color: "#FFFC00" }} />
      );
    case "Spotify":
      return (
        <FaSpotify className="platform-icon" style={{ color: "#1DB954" }} />
      );
    case "Microsoft Teams":
      return (
        <FaMicrosoft className="platform-icon" style={{ color: "#6264A7" }} />
      );
    case "Telegram":
      return (
        <FaTelegram className="platform-icon" style={{ color: "#0088CC" }} />
      );
    case "Tiktok":
      return (
        <FaTiktok className="platform-icon" style={{ color: "#000000" }} />
      );
    default:
      return null;
  }
};
const ProfileInvitePage = () => {
  const [t] = useTranslation("global");
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { nick_name } = useParams();
  console.log("nick_name", nick_name);
  // const pageId = `${userId}/${meetId}/${objective}`;
  const [pageViews, setPageViews] = useState(0);

  useEffect(() => {
    const incrementPageView = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/user-page-views/${nick_name}`
        );
        console.log("response views", response);
        if (response.status === 200) {
          setPageViews(response?.data?.views_count);
        }
      } catch (error) {
        console.error("Error incrementing page view:", error);
      }
    };
    incrementPageView();
  }, [nick_name]);

  const [visibilityMessage, setVisibilityMessage] = useState(null);

  useEffect(() => {
    const getUser = async () => {
      setLoading(true);

      // Extract the part after the underscore
      // const parts = nick_name?.split('_');
      // const suffix = parts[parts?.length - 1];
      // console.log('suffix',suffix)
      // const isNumeric = /^\d+$/.test(suffix);
      const apiUrl = `${API_BASE_URL}/public-user/${nick_name}`;
      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        });
        console.log("response of public user api-->", response?.data?.data);
        if (response.status === 200) {
          setUser(response.data?.data);
        }
      } catch (error) {
        setLoading(false);
        console.log("error while fetching user", error);
        setVisibilityMessage(error?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    };

    getUser();
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    if (visibilityMessage) {
      setIsModalOpen(true);
    }
  }, [visibilityMessage]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // useEffect(() => {
  //   console.log("isModalOpen:", isModalOpen);
  // }, [isModalOpen]);

  const visitingCardUrl =
    window.location.origin + "/" + user?.nick_name + "/" + user?.uuid;

    const formatUrl = (url) => {
      if (!url) return "";
      if (!/^https?:\/\//i.test(url)) {
        return `https://${url}`; // Default to https if no scheme is provided
      }
      return url;
    };
  const handleOpen = (link) => {
    window.open(formatUrl(link), "_blank");
  };
  const [dropdownVisible, setDropdownVisible] = useState(
    Array(user?.websites?.length).fill(false)
  );
  const dropdownRefs = useRef([]);

  const toggleDropdown = (index) => {
    setDropdownVisible((prev) => {
      const newDropdownVisible = [...prev];
      newDropdownVisible[index] = !newDropdownVisible[index];
      return newDropdownVisible;
    });
  };
  useEffect(() => {
    user?.websites?.forEach((item, index) => {
      const el = dropdownRefs.current[index];
      if (el) {
        if (dropdownVisible[index]) {
          el.style.display = "block";
          requestAnimationFrame(() => {
            el.classList.add("show");
          });
        } else {
          el.classList.remove("show");
          el.addEventListener(
            "transitionend",
            () => {
              el.style.display = "none";
            },
            { once: true }
          );
        }
      }
    });
  }, [dropdownVisible, user]);

  //For Affiliation
  const [dropdownVisible1, setDropdownVisible1] = useState(
    Array(user?.affiliation_links?.length).fill(false)
  );
  const dropdownRefs1 = useRef([]);

  const toggleDropdown1 = (index) => {
    setDropdownVisible1((prev) => {
      const newDropdownVisible = [...prev];
      newDropdownVisible[index] = !newDropdownVisible[index];
      return newDropdownVisible;
    });
  };
  useEffect(() => {
    user?.affiliation_links?.forEach((item, index) => {
      const el = dropdownRefs1.current[index];
      if (el) {
        if (dropdownVisible1[index]) {
          el.style.display = "block";
          requestAnimationFrame(() => {
            el.classList.add("show");
          });
        } else {
          el.classList.remove("show");
          el.addEventListener(
            "transitionend",
            () => {
              el.style.display = "none";
            },
            { once: true }
          );
        }
      }
    });
  }, [dropdownVisible1, user]);

  const handleNavigate = () => {
    window.location.href =
      "https://calendly.com/tektime/tektime-qu-est-ce-que-c-est";
  };
  return (
    <>
      {loading ? (
        <Spinner animation="border" role="status" className="center-spinner" />
      ) : (
        <>
          {isModalOpen ? (
            <VisibilityMessageModal
              message={visibilityMessage}
              onClose={closeModal}
            />
          ) : (
            <div
              className="profile-link w-100"
              style={{ position: "relative" }}
            >
              <div className="gradient-header">
                <svg
                  width="100%"
                  height="338"
                  viewBox="0 0 1453 338"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_f_902_7997)">
                    <rect
                      width="100%"
                      height="271"
                      fill="url(#paint0_linear_902_7997)"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_f_902_7997"
                      x="-66.5"
                      y="-66.5"
                      width="1573"
                      height="404"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                      />
                      <feGaussianBlur
                        stdDeviation="33.25"
                        result="effect1_foregroundBlur_902_7997"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_902_7997"
                      x1="856"
                      y1="281.934"
                      x2="863.131"
                      y2="-138.913"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="white" />
                      <stop offset="1" stop-color="#76C3EE" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div className="content">
                <div
                  className="row align-items-center"
                  // style={{ height: "35vh", margin: "0px 80px" }}
                >
                  <div className="col-md-6 col-6 d-flex flex-column">
                    <h5 className="content-heading fw-bold">
                      {user?.title
                        ? user?.title
                        : t("profile.inviteProfile.visitingCard")}
                      {/* {t("profile.inviteProfile.visitingCard")} */}
                    </h5>
                  </div>

                  <div className="col-md-6 col-6 d-flex justify-content-end">
                    {/* <div className="position-relative"> */}
                    <Card
                      // className="mt-3"
                      style={{
                        width: "fit-content",
                        // margin: "auto",
                        borderRadius: "119px",
                      }}
                    >
                      <Card.Body>
                        <div className="d-flex justify-content-center">
                          <div className="profile-logo">
                            <Card.Img
                              className="avatar-img"
                              src={
                                user?.enterprise?.logo
                                  ? Assets_URL + "/" + user?.enterprise?.logo
                                  : "/Assets/logo2.png"
                              }
                            />
                          </div>
                        </div>
                        {/* 
                        <Card.Title className="text-center mt-3 card-heading">
                          {user?.enterprise?.name}
                        </Card.Title> */}
                      </Card.Body>
                    </Card>
                    {/* </div> */}
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-md-12">
                    <Card
                      className={`participant-card
            participant-card-meeting
           position-relative`}
                      style={{
                        marginTop: "4rem",
                        margin: "auto",
                        width: "fit-content",
                      }}
                    >
                      <Card.Body
                        style={{
                          padding: "35px 80px",
                        }}
                      >
                        <div className="d-flex justify-content-center">
                          <div className="participant-card-position">
                            <div className="profile-logo">
                              <Card.Img
                                className="user-img"
                                src={
                                  user?.image?.startsWith("users/")
                                    ? `${Assets_URL}/${user?.image}`
                                    : user?.image
                                }
                                // width="150px"
                                // height="150px"
                                style={{
                                  height: "150px",
                                  width: "150px",
                                }}
                                alt="User Avatar"
                              />
                              <Card.Img
                                className="logout-icon"
                                src="/Assets/Avatar_company.svg"
                                height="20px"
                                width="20px"
                                alt="Company Logo"
                              />
                            </div>
                          </div>
                        </div>

                        <Card.Title className="text-center mt-4 card-heading">
                          {user?.name} {user?.last_name}
                        </Card.Title>
                        <Card.Title className="text-center mb-2 card-subtext">
                          {user?.email}
                        </Card.Title>
                        <Card.Title className="text-center mb-2 card-subtext">
                          {user?.phoneNumber}
                        </Card.Title>
                        <Card.Title className="mb-2 card-subtext">
                          {/* {data?.post} {data?.team_names?.length > 0 && '-'} {data?.team_names?.map((item) => item).join(", ")} */}
                          {user?.post}
                        </Card.Title>
                        <Card.Title className="mb-2 card-subtext">
                          {user?.teams?.map((item) => item.name).join(", ")}
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </div>
                </div>

                {user?.bio && (
                  <div className="row mt-5">
                    <div className="col-md-12">
                      <div className="bio">
                        <h3> {t("profile.inviteProfile.bio")}</h3>
                        <p>{user?.bio}</p>
                      </div>
                    </div>
                  </div>
                )}

                {user?.social_links?.length > 0 && (
                  <div className="row mt-5 ">
                    <div className="col-md-12">
                      <div className="bio">
                        <h3>{t("profile.inviteProfile.socialLinks")}</h3>
                        {/* <p>{user?.bio}</p> */}

                        {user?.social_links?.map((item, index) => {
                          return (
                            <div className="col-12 email-step" key={index}>
                              <Card
                                className="mt-4 step-card social-card"
                                onClick={() => handleOpen(item.link)}
                                style={{ cursor: "pointer" }}
                              >
                                <Card.Body className="d-flex">
                                  <div className="d-flex align-items-center">
                                    <div className="step-number-container">
                                      <span className="step-number">
                                        {index < 10 ? "0" : ""}
                                        {index + 1}
                                      </span>
                                    </div>

                                    {getPlatformIcon(item?.platform)}
                                  </div>
                                  <div className="ms-3 d-flex justify-content-center flex-column step-data">
                                    <Card.Title
                                      onClick={() => handleOpen(item.link)}
                                      className="step-card-heading"
                                      style={{ cursor: "pointer" }}
                                    >
                                      {item?.platform}
                                    </Card.Title>

                                    {/* <Card.Subtitle className="step-card-subtext">
                                  <Link to={item?.link}>{item?.link}</Link>
                                </Card.Subtitle> */}
                                  </div>
                                </Card.Body>
                              </Card>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
                {user?.websites?.length > 0 && (
                  <div className="row mt-5">
                    <div className="col-md-12">
                      <div className="bio">
                        <h3>{t("profile.inviteProfile.websites")}</h3>

                        {user?.websites?.map((item, index) => {
                          return (
                            <div className="col-12 email-step" key={index}>
                              <Card
                                className="mt-4 step-card"
                                style={{ cursor: "pointer" }}
                                onClick={() => toggleDropdown(index)}
                              >
                                <Card.Body className="d-flex">
                                  <div
                                    className={` ${
                                      dropdownVisible[index]
                                        ? "d-block"
                                        : "d-block d-flex align-items-start"
                                    }`}
                                  >
                                    <div className="step-number-container">
                                      <span className="step-number">
                                        {index < 10 ? "0" : ""}
                                        {index + 1}
                                      </span>
                                    </div>

                                    {/* {getPlatformIcon(item?.platform)} */}
                                  </div>
                                  <div className="ms-3 d-flex justify-content-center flex-column step-data w-100">
                                    <div className="mx-3 d-flex justify-content-between align-items-center step-data w-100">
                                      <Card.Title className="step-card-heading">
                                        {item?.title}
                                      </Card.Title>
                                      <MdKeyboardArrowDown
                                        // onClick={() => toggleDropdown(index)}
                                        style={{
                                          cursor: "pointer",
                                          marginRight: "21px",
                                        }}
                                        size={26}
                                      />
                                    </div>
                                  </div>
                                </Card.Body>
                                {dropdownVisible[index] && (
                                  <div
                                    className="dropdown-content-1  fade"
                                    ref={(el) =>
                                      (dropdownRefs.current[index] = el)
                                    }
                                    style={{ display: "none", margin: "6px" }}
                                  >
                                    <div className="dropdown-section-1">
                                      <iframe
                                        src={formatUrl(item?.link)}
                                        frameborder="0"
                                        allowFullScreen
                                        style={{
                                          height: "500px",
                                          width: "100%",
                                          maxHeight: "500px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}
                              </Card>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
                {user?.affiliation_links?.length > 0 && (
                  <div className="row mt-5">
                    <div className="col-md-12">
                      <div className="bio">
                        <h3>{t("profile.inviteProfile.affiliation")}</h3>

                        {user?.affiliation_links?.map((item, index) => {
                          return (
                            <div className="col-12 email-step" key={index}>
                              <Card
                                className="mt-4 step-card"
                                style={{ cursor: "pointer" }}
                                onClick={() => toggleDropdown1(index)}
                              >
                                <Card.Body className="d-flex">
                                  <div
                                    className={` ${
                                      dropdownVisible1[index]
                                        ? "d-block"
                                        : "d-block d-flex align-items-start"
                                    }`}
                                  >
                                    <div className="step-number-container">
                                      <span className="step-number">
                                        {index < 10 ? "0" : ""}
                                        {index + 1}
                                      </span>
                                    </div>

                                    {/* {getPlatformIcon(item?.platform)} */}
                                  </div>
                                  <div className="ms-3 d-flex justify-content-center flex-column step-data w-100">
                                    <div className="mx-3 d-flex justify-content-between align-items-center step-data w-100">
                                      <Card.Title className="step-card-heading">
                                        {item?.title}
                                      </Card.Title>
                                      <MdKeyboardArrowDown
                                        // onClick={() => toggleDropdown(index)}
                                        style={{
                                          cursor: "pointer",
                                          marginRight: "21px",
                                        }}
                                        size={26}
                                      />
                                    </div>
                                  </div>
                                </Card.Body>
                                {dropdownVisible1[index] && (
                                  <div
                                    className="dropdown-content-1  fade"
                                    ref={(el) =>
                                      (dropdownRefs1.current[index] = el)
                                    }
                                    style={{ display: "none", margin: "6px" }}
                                  >
                                    <div className="dropdown-section-1">
                                      <iframe
                                        // src={item.link}
                                        src={formatUrl(item?.link)}
                                        frameborder="0"
                                        allowFullScreen
                                        style={{
                                          height: "500px",
                                          width: "100%",
                                          maxHeight: "500px",
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}
                              </Card>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}

                <div className="row mt-5">
                  <div className="join-btn d-flex justify-content-center">
                    <button
                      style={{
                        fontFamily: "Inter",
                        fontSize: "14px",
                        fontWeight: 600,
                        lineHeight: "24px",
                        textAlign: "left",
                        color: " #FFFFFF",
                        background: "#2C48AE",
                        border: 0,
                        outine: 0,
                        padding: "10px 16px",
                        borderRadius: "9px",
                        // marginTop: "1.5rem",
                      }}
                      onClick={handleNavigate}
                    >
                      {t("joinTektime")}
                    </button>
                  </div>
                </div>

                {user?.video && user?.video !== "null" && (
                  <div className="row mt-5">
                    <div className="col-md-12">
                      <div className="bio">
                        <h3> {t("profile.inviteProfile.presentation")}</h3>

                        <div className="embed-responsive embed-responsive-16by9 mb-4 text-center  d-flex align-items-center justify-content-center">
                          <ReactPlayer
                            url={
                              Assets_URL + "/" + user?.video ||
                              "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"
                            }
                            className="react-player-video"
                            controls={true}
                            style={{ borderRadius: "4px" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="d-flex justify-content-center align-items-center view-count">
                  <div className="d-flex flex-column align-items-center">
                    <span>
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 15.5C13.6569 15.5 15 14.1569 15 12.5C15 10.8431 13.6569 9.5 12 9.5C10.3431 9.5 9 10.8431 9 12.5C9 14.1569 10.3431 15.5 12 15.5Z"
                          stroke="black"
                          stroke-width="2"
                        />
                        <path
                          d="M21 12.5C21 12.5 20 4.5 12 4.5C4 4.5 3 12.5 3 12.5"
                          stroke="black"
                          stroke-width="2"
                        />
                      </svg>
                    </span>
                    <p className="page-count">{pageViews} page views</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ProfileInvitePage;
