import axios from "axios";
import React, { useEffect, useState } from "react";
import { BiShow, BiHide } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API_BASE_URL } from "../Apicongfig";

const Login = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  console.log("location", location);
  const [show, setShow] = useState(false);

  const toProfilePage = location?.state?.fromInvitePage;
  const invitePageURL = location?.state?.invitePageURL;
  console.log("toProfilePage", toProfilePage);
  console.log("invitePageURL", invitePageURL);

  // Function to check token expiration and refresh
  function checkTokenExpiration() {
    const tokenExpirationTime =
      sessionStorage.getItem("token_expiration_time") ||
      localStorage.getItem("token_expiration_time");

    console.log("Checking token expiration", tokenExpirationTime);
    if (!tokenExpirationTime) return;

    const currentTime = Date.now();
    if (currentTime > tokenExpirationTime) {
      console.log("Access token expired. Refreshing...");
      refreshAccessToken(); // Call the function to refresh the access token
    } else {
      console.log("Token is still valid.");
    }
  }
  function refreshAccessToken() {
    const refreshToken = localStorage.getItem("refresh_token");

    if (!refreshToken) {
      console.error("No refresh token available");
      return;
    }
    const userid = localStorage.getItem("user_id");

    axios
      .post(
        `${API_BASE_URL}/auth/refresh`,
        {
          // refresh_token: refreshToken,
          user_id: userid,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("Refresh API Response:", response.data);
        if (response) {
          const newAccessToken = response.data?.access_token;
          const newExpiresIn = response.data?.expires_in; // e.g., 3598 seconds

          // Update the stored tokens and expiration time
          sessionStorage.setItem("access_token", newAccessToken);
          localStorage.setItem("access_token", newAccessToken);

          const newExpirationTime = Date.now() + newExpiresIn * 1000;
          sessionStorage.setItem("token_expiration_time", newExpirationTime);
          localStorage.setItem("token_expiration_time", newExpirationTime);

          // Set a new timeout to refresh the access token again
          setTimeout(refreshAccessToken, (newExpiresIn - 60) * 1000); // Refresh 1 minute before expiration
        }
      })
      .catch((error) => {
        console.error("Refresh API Error:", error);
        // Optionally handle token refresh errors (e.g., log out user)
        // If the refresh token is invalid (e.g., 401 Unauthorized), handle re-authentication
        // if (error.response && error.response.status === 401) {
        console.error(
          "Refresh token is invalid or expired. Redirecting to login."
        );
        handleInvalidToken();
        // }
      });
  }
  function handleInvalidToken() {
    sessionStorage.removeItem("access_token");
    localStorage.removeItem("access_token");
    sessionStorage.removeItem("refresh_token");
    localStorage.removeItem("refresh_token");
    sessionStorage.removeItem("token_expiration_time");
    localStorage.removeItem("token_expiration_time");
  }
  const handleLoginSubmit = async (event) => {
    // setShow(false);
    event.preventDefault();

    try {
      const response = await axios.post(`${API_BASE_URL}/login`, {
        email: email,
        password: password,
      });

      if (response) {
        const { id, name, email } = response.data.user;
        const accessToken = response.data?.user?.access_token;
        const refreshToken = response.data?.user?.refresh_token;
        const tokenExpirationTime = response.data?.user?.token_expiration_time;

        sessionStorage.setItem("token", response.data.token);
        sessionStorage.setItem("user_id", id);
        localStorage.setItem("user_id", id);
        sessionStorage.setItem("user", JSON.stringify(response.data.user));
        sessionStorage.setItem("email", email);
        sessionStorage.setItem("name", name);
        sessionStorage.setItem("type", response.data.user.role.name);
        sessionStorage.setItem("role", JSON.stringify(response.data.user.role));

        // Handle access_token
        if (accessToken && accessToken !== "null") {
          sessionStorage.setItem("access_token", accessToken);
          localStorage.setItem("access_token", accessToken);
        } else {
          sessionStorage.removeItem("access_token");
          localStorage.removeItem("access_token");
        }

        // Handle refresh_token
        if (refreshToken && refreshToken !== "null") {
          sessionStorage.setItem("refresh_token", refreshToken);
          localStorage.setItem("refresh_token", refreshToken);
        } else {
          sessionStorage.removeItem("refresh_token");
          localStorage.removeItem("refresh_token");
        }

        // Handle token_expiration_time
        if (tokenExpirationTime && tokenExpirationTime !== "null") {
          sessionStorage.setItem("token_expiration_time", tokenExpirationTime);
          localStorage.setItem("token_expiration_time", tokenExpirationTime);
        } else {
          sessionStorage.removeItem("token_expiration_time");
          localStorage.removeItem("token_expiration_time");
        }
        // sessionStorage.setItem(
        //   "access_token",
        //   response.data?.user?.access_token
        // );
        // sessionStorage.setItem(
        //   "refresh_token",
        //   response.data?.user?.refresh_token
        // );
        // sessionStorage.setItem(
        //   "token_expiration_time",
        //   response.data?.user?.token_expiration_time
        // );
        // localStorage.setItem("access_token", response.data?.user?.access_token);
        // localStorage.setItem(
        //   "refresh_token",
        //   response.data?.user?.refresh_token
        // );
        // localStorage.setItem(
        //   "token_expiration_time",
        //   response.data?.user?.token_expiration_time
        // );

        const userRole = response.data.user.role.name;
        if ((userRole === "MasterAdmin", "SuperAdmin", "Admin")) {
          toast.success("Connexion réussie");
          navigate(toProfilePage ? invitePageURL : "/meeting");

          // if (toProfilePage) {
          //   navigate(invitePageURL);
          // } else {
          //   navigate("/meeting");
          // }
        } else {
          navigate(toProfilePage ? invitePageURL : "/meeting");

          // navigate("/meeting");
        }
        // Check token expiration right after login
        checkTokenExpiration(); // Check token expiration and refresh if needed

        // Optionally set up a token refresh interval
        const expiresIn = tokenExpirationTime - Date.now();
        if (expiresIn > 0) {
          setTimeout(refreshAccessToken, expiresIn - 60000); // Refresh 1 minute before expiration
        }
      } else {
        toast.error("La connexion a échoué");
      }
    } catch (error) {
      // console.log("error while logging in", error);
      // toast.error("Veuillez vérifier votre email et votre mot de passe");
      if (
        error?.response?.status === 403 &&
        error?.response?.data?.success === false &&
        error?.response?.data?.message === "Enterprise Status Closed!"
      ) {
        // toast.error(error.response.data.message);
        setShow(true);
      } else {
        toast.error("Veuillez vérifier votre email et votre mot de passe");
      }
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleEmailClick = () => {
    const email = "portal@tektime.fr";
    window.open(`mailto:${email}`);
  };

  return (
    <>
      <div className="login">
        <div className="container-fluid pb-5">
          <div className={`row justify-content-center ${show ? `blur` : ""}`}>
            <div className="col-md-4">
              <div className="card px-5 py-4">
                <form
                  onSubmit={handleLoginSubmit}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent form submission
                      handleLoginSubmit(e); // Pass the event object to the function
                    }
                  }}
                >
                  <div>
                    <div className="text-center">
                      <img
                        src="/Assets/logo5.png"
                        alt="none"
                        className="img-fluid"
                        width={180}
                      />
                      {/* <p className="">Connexion</p> */}
                    </div>
                    <div className="mb-3">
                      <label for="exampleInputEmail1" className="form-label">
                        E-mail
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        disabled={show ? true : false}
                      />
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label pass-lab"
                      >
                        Mot de passe
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={togglePasswordVisibility}
                        >
                          {passwordVisible ? (
                            <BiHide color="#145CB8" />
                          ) : (
                            <BiShow color="#145CB8" />
                          )}
                        </button>
                      </label>
                      <div className="input-group">
                        <input
                          type={passwordVisible ? "text" : "password"}
                          className="form-control"
                          id="exampleInputPassword1"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                          disabled={show ? true : false}
                        />
                      </div>
                    </div>
                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-login"
                        disabled={show ? true : false}
                        // onKeyDown={(e)=>handleKeyDown(e)}
                      >
                        Connexion
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {show && (
        <>
          <div className="box" style={{ width: "100%", height: "100%" }}>
            <div className={`row justify-content-center`}>
              <div
                className="col-md-4"
                style={{
                  position: "absolute",
                  top: "22%",
                }}
              >
                <div className="card px-4 py-4">
                  <div className="text-center">
                    <img
                      src="/Assets/logo5.png"
                      alt="none"
                      className="img-fluid"
                      width={180}
                    />
                  </div>
                  <div className="alert alert-danger  pr-0">
                    <h6 className="text-center">
                      Votre abonnement TekTIME vient de toucher à sa fin
                    </h6>
                    <small>
                      Ce n'est pas perdu je vous invite à vous rapprocher de
                      votre administrateur pour prolonger l'aventure ou
                      d'envoyer un mail à{" "}
                      <a href="" onClick={handleEmailClick}>
                        portal@tektime.fr
                      </a>
                      . Je vous dit à très vite
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* )} */}
    </>
  );
};

export default Login;
