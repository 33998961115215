import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Nav,
  Tab,
  Form,
  Button,
  Card,
  InputGroup,
  DropdownButton,
  Dropdown,
  Spinner,
} from "react-bootstrap";
import {
  FaLinkedin,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaBehance,
  FaDiscord,
  FaDribbble,
  FaGoogle,
  FaLine,
  FaFacebookMessenger,
  FaPinterest,
  FaQq,
  FaReddit,
  FaSkype,
  FaSlack,
  FaSnapchat,
  FaSpotify,
  FaMicrosoft,
  FaTelegram,
  FaTiktok,
  FaYoutube,
} from "react-icons/fa";
import Select from "react-select";

import { FaEye, FaEyeSlash } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { IoPlayOutline, IoShareSocialOutline } from "react-icons/io5";
import { RiDeleteBin5Line, RiLockPasswordLine } from "react-icons/ri";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { FaUserGroup, FaWhatsapp } from "react-icons/fa6";
import { GoPlus } from "react-icons/go";
import { API_BASE_URL, Assets_URL } from "../../Apicongfig";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useHeaderTitle } from "../../../context/HeaderTitleContext";
import { getUserRoleID } from "../../Utils/getSessionstorageItems";

const AddProfile = ({ user, teams, refreshUserData, isLoading }) => {
  const { profileImage, setProfileImage } = useHeaderTitle();
  const navigate = useNavigate();
  const options = [
    {
      value: "LinkedIn",
      label: (
        <>
          <FaLinkedin style={{ color: "#0A66C2" }} /> LinkedIn
        </>
      ),
    },
    {
      value: "Facebook",
      label: (
        <>
          <FaFacebook style={{ color: "#1877F2" }} /> Facebook
        </>
      ),
    },
    {
      value: "Youtube",
      label: (
        <>
          <FaYoutube style={{ color: "red" }} /> Youtube
        </>
      ),
    },
    {
      value: "Whatsapp",
      label: (
        <>
          <FaWhatsapp style={{ color: "green" }} /> Whatsapp
        </>
      ),
    },
    {
      value: "Twitter",
      label: (
        <>
          <FaTwitter style={{ color: "#1DA1F2" }} /> Twitter
        </>
      ),
    },
    {
      value: "Instagram",
      label: (
        <>
          <FaInstagram style={{ color: "#C13584" }} /> Instagram
        </>
      ),
    },
    {
      value: "Behance",
      label: (
        <>
          <FaBehance style={{ color: "#1769FF" }} /> Behance
        </>
      ),
    },
    {
      value: "Discord",
      label: (
        <>
          <FaDiscord style={{ color: "#7289DA" }} /> Discord
        </>
      ),
    },
    {
      value: "Dribble",
      label: (
        <>
          <FaDribbble style={{ color: "#EA4C89" }} /> Dribble
        </>
      ),
    },
    {
      value: "Google My Business",
      label: (
        <>
          <FaGoogle style={{ color: "#4285F4" }} /> Google My Business
        </>
      ),
    },

    {
      value: "Line",
      label: (
        <>
          <FaLine style={{ color: "#00C300" }} /> Line
        </>
      ),
    },
    {
      value: "Messenger",
      label: (
        <>
          <FaFacebookMessenger style={{ color: "#0084FF" }} /> Messenger
        </>
      ),
    },
    {
      value: "Pinterest",
      label: (
        <>
          <FaPinterest style={{ color: "#E60023" }} /> Pinterest
        </>
      ),
    },
    {
      value: "QQ",
      label: (
        <>
          <FaQq style={{ color: "#00B2A9" }} /> QQ
        </>
      ),
    },
    {
      value: "Reddit",
      label: (
        <>
          <FaReddit style={{ color: "#FF4500" }} /> Reddit
        </>
      ),
    },
    {
      value: "Skype",
      label: (
        <>
          <FaSkype style={{ color: "#00AFF0" }} /> Skype
        </>
      ),
    },
    {
      value: "Slack",
      label: (
        <>
          <FaSlack style={{ color: "#4A154B" }} /> Slack
        </>
      ),
    },
    {
      value: "Snapchat",
      label: (
        <>
          <FaSnapchat style={{ color: "#FFFC00" }} /> Snapchat
        </>
      ),
    },
    {
      value: "Spotify",
      label: (
        <>
          <FaSpotify style={{ color: "#1DB954" }} /> Spotify
        </>
      ),
    },
    {
      value: "Microsoft Teams",
      label: (
        <>
          <FaMicrosoft style={{ color: "#6264A7" }} /> Microsoft Teams
        </>
      ),
    },
    {
      value: "Telegram",
      label: (
        <>
          <FaTelegram style={{ color: "#0088CC" }} /> Telegram
        </>
      ),
    },
    {
      value: "Tiktok",
      label: (
        <>
          <FaTiktok style={{ color: "#000000" }} /> Tiktok
        </>
      ),
    },
  ];

  const [t] = useTranslation("global");

  const [socialLinks, setSocialLinks] = useState([]);
  const [websites, setWebsites] = useState([]);
  const [affiliationLinks, setAffilicationLinks] = useState([]);
  const [title, setTitle] = useState(user?.title);
  const [loading, setLoading] = useState(false);
  // Function to handle adding a new social link
  const addSocialLink = () => {
    setSocialLinks([...socialLinks, { platform: "", link: "" }]);
  };

  // Function to handle removing a social link
  const removeSocialLink = async (item, index) => {
    try {
      if (item?.id) {
        // if the social has an ID (existing social link)
        const response = await axios.delete(
          `${API_BASE_URL}/social-links/${item.id}`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );

        if (response?.status === 200) {
          // Update the state by removing the deleted social link
          setSocialLinks((prevSocialLinks) =>
            prevSocialLinks.filter((_, i) => i !== index)
          );
        }
      } else {
        // If the social doesn't have an ID, it's a new social link (not saved in the backend yet)
        setSocialLinks((prevLinks) => prevLinks.filter((_, i) => i !== index));
      }
    } catch (error) {
      console.log("Error while deleting website", error);
    }
  };

  // Function to handle adding a new website
  const addWebsite = () => {
    setWebsites([...websites, { title: "", link: "" }]);
  };

  const removeWebsite = async (item, index) => {
    try {
      if (item?.id) {
        const response = await axios.delete(
          `${API_BASE_URL}/website/${item.id}`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );

        if (response?.status === 200) {
          // Update the state by removing the deleted website
          setWebsites((prevWebsites) =>
            prevWebsites.filter((_, i) => i !== index)
          );
        }
      } else {
        // If the website doesn't have an ID, it's a new website
        setWebsites((prevWebsites) =>
          prevWebsites.filter((_, i) => i !== index)
        );
      }
    } catch (error) {
      console.log("Error while deleting website", error);
    }
  };
  // Function to handle adding a new affilication links
  const addAffiliationLinks = () => {
    setAffilicationLinks([...affiliationLinks, { title: "", link: "" }]);
  };

  const removeAffiliationLinks = async (item, index) => {
    try {
      if (item?.id) {
        const response = await axios.delete(
          `${API_BASE_URL}/affiliation-links/${item.id}`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );

        if (response?.status === 200) {
          // Update the state by removing the deleted Affilication
          setAffilicationLinks((prevAffiliationLinks) =>
            prevAffiliationLinks.filter((_, i) => i !== index)
          );
        }
      } else {
        // If the Affilication doesn't have an ID, it's a new Affilication
        setAffilicationLinks((prevAffiliationLinks) =>
          prevAffiliationLinks.filter((_, i) => i !== index)
        );
      }
    } catch (error) {
      console.log("Error while deleting Affilication", error);
    }
  };

  // const handleAffiliationLinkChange = (index, event) => {
  //   const { name, value } = event.target;
  //   const updatedAffiliationLink = [...affiliationLinks];
  //   updatedAffiliationLink[index][name] = value;
  //   setAffilicationLinks(updatedAffiliationLink);
  // };
  const handleAffiliationLinkChange = (index, event) => {
    const { name, value } = event.target;
    const updatedAffiliationLink = [...affiliationLinks];

    // If the link is a YouTube link, convert it to the embed format
    if (name === "link" && value.includes("youtube.com/watch")) {
      const url = new URL(value);
      const videoId = url.searchParams.get("v");
      if (videoId) {
        updatedAffiliationLink[index][
          name
        ] = `https://www.youtube.com/embed/${videoId}`;
      } else {
        updatedAffiliationLink[index][name] = value;
      }
    } else {
      updatedAffiliationLink[index][name] = value;
    }

    setAffilicationLinks(updatedAffiliationLink);
  };

  const handleSocialChange = (index, event) => {
    const { name, value } = event.target;
    const updatedSocialLinks = [...socialLinks];
    updatedSocialLinks[index][name] = value;
    setSocialLinks(updatedSocialLinks);
  };

  const handleSelectChange = (index, selectedOption) => {
    const updatedSocialLinks = [...socialLinks];
    updatedSocialLinks[index].platform = selectedOption
      ? selectedOption.value
      : "";
    setSocialLinks(updatedSocialLinks);
  };
  // Handle changes for websites
  const handleWebsiteChange = (index, event) => {
    const { name, value } = event.target;
    const updatedWebsites = [...websites];
    // updatedWebsites[index][name] = value;
    // setWebsites(updatedWebsites);

    // If the link is a YouTube link, convert it to the embed format
    if (name === "link" && value.includes("youtube.com/watch")) {
      const url = new URL(value);
      const videoId = url.searchParams.get("v");
      if (videoId) {
        updatedWebsites[index][
          name
        ] = `https://www.youtube.com/embed/${videoId}`;
      } else {
        updatedWebsites[index][name] = value;
      }
    } else {
      updatedWebsites[index][name] = value;
    }

    setWebsites(updatedWebsites);
  };

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRetypePassword, setShowRetypePassword] = useState(false);
  const maxLength = 800;

  const togglePasswordVisibility = (setShowFunction, showState) => {
    setShowFunction(!showState);
  };

  const [visibility, setVisibility] = useState(null);
  const [selectedTeams, setSelectedTeams] = useState([]);

  const [availableTeams, setAvailableTeams] = useState([]);
  const handleVisibilityChange = (e) => {
    setVisibility(e.target.value);
  };

  // const handleTeamSelect = (teamId) => {
  //   const id = parseInt(teamId);
  //   // Add the team ID to selectedTeams if it's not already selected
  //   if (!selectedTeams.includes(id)) {
  //     setSelectedTeams([...selectedTeams, id]);
  //   }
  // };
  const handleTeamSelect = (teamId) => {
    const id = parseInt(teamId);
    const selectedTeam = availableTeams.find((team) => team.id === id);

    // Add the team object to selectedTeams if it's not already selected
    if (selectedTeam && !selectedTeams.some((team) => team.value === id)) {
      setSelectedTeams([
        ...selectedTeams,
        { value: id, label: selectedTeam.name },
      ]);
    }
  };

  const removeTeam = (teamId) => {
    // Remove the team from selectedTeams based on the `value` field
    setSelectedTeams(selectedTeams.filter((team) => team.value !== teamId));
  };
  // const removeTeam = (teamId) => {
  //   // Remove the team ID from selectedTeams
  //   setSelectedTeams(selectedTeams.filter((id) => id !== teamId));
  // };

  const [videoPreview, setVideoPreview] = useState(null);
  console.log("video preview", videoPreview);
  const videoFileInputRef = useRef(null);
  useEffect(() => {
    console.log("videoFileInputRef current:", videoFileInputRef.current);
  }, [videoFileInputRef]);
  const handleVideoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      if (
        (fileType === "video/mp4" || fileType === "video/x-m4v") &&
        file.size <= 50 * 1024 * 1024
      ) {
        setVideoPreview(URL.createObjectURL(file));
      } else {
        alert("Please upload a valid MP4 file up to 50MB.");
      }
    }
  };

  const handleVideoRemove = async () => {
    // setVideoPreview(null);
    try {
      const formDataInstance = new FormData();

      // Handle profile image
      if (profile.startsWith("data:image/")) {
        const response = await fetch(profile);
        const blob = await response.blob();
        formDataInstance.append("image", blob, "profile-image.jpg");
      } else {
        formDataInstance.append("image", profile);
      }

      formDataInstance.append("name", formData.name || "");
      formDataInstance.append("last_name", formData.last_name || "");
      formDataInstance.append("nick_name", formData.nick_name || "");
      formDataInstance.append("email", formData.email || "");
      formDataInstance.append("phoneNumber", formData.phoneNumber || "");
      formDataInstance.append("enterprise_id", formData.enterprise_id || "");
      formDataInstance.append("bio", formData.bio || "");
      formDataInstance.append("post", formData.post || "");
      formDataInstance.append("role_id", user?.role_id || "");

      // socialLinks.forEach((link, index) => {
      //   formDataInstance.append(
      //     `social_links[${index}][platform]`,
      //     link.platform
      //   );
      //   formDataInstance.append(`social_links[${index}][link]`, link.link);
      // });

      socialLinks.forEach((link, index) => {
        if (link.id) {
          // Existing link (include ID)
          formDataInstance.append(`social_links[${index}][id]`, link.id);
        }
        formDataInstance.append(
          `social_links[${index}][platform]`,
          link.platform
        );
        formDataInstance.append(`social_links[${index}][link]`, link.link);
      });

      websites.forEach((site, index) => {
        if (site.id) {
          // Existing link (include ID)
          formDataInstance.append(`websites[${index}][id]`, site.id);
        }
        formDataInstance.append(`websites[${index}][title]`, site.title);
        formDataInstance.append(`websites[${index}][link]`, site.link);
      });

      affiliationLinks.forEach((site, index) => {
        if (site.id) {
          // Existing link (include ID)
          formDataInstance.append(`affiliation_links[${index}][id]`, site.id);
        }
        formDataInstance.append(
          `affiliation_links[${index}][title]`,
          site.title
        );
        formDataInstance.append(`affiliation_links[${index}][link]`, site.link);
      });

      // formDataInstance.append("socialLinks", JSON.stringify(socialLinks || []));
      // formDataInstance.append("websites", JSON.stringify(websites || []));

      // Handle video
      formDataInstance.append("video", null);

      formDataInstance.append("current_password", currentPassword || "");
      formDataInstance.append("password", newPassword || "");
      formDataInstance.append("password_confirmation", retypePassword || "");

      formDataInstance.append("visibility", visibility || "public");
      formDataInstance.append("_method", "put");

      // Conditionally append teams
      // if (visibility === "team") {
      // formDataInstance.append("team_id", JSON.stringify(selectedTeams || []));
      // const teamArray = selectedTeams || [];
      // teamArray.forEach((teamId) => {
      //   formDataInstance.append("team_id[]", teamId);
      // });
      // }

      const teamIds = Array.isArray(formData.team_id)
        ? formData.team_id
        : formData.team_id.split(",").map((id) => parseInt(id.trim()));

      console.log("teamIds", teamIds);
      teamIds.forEach((teamId) => {
        formDataInstance.append("team_id[]", teamId);
      });
      // formDataInstance.append("team_id", teamIds);

      for (let pair of formDataInstance.entries()) {
        console.log(pair[0] + ": " + pair[1]);
      }

      const response = await fetch(`${API_BASE_URL}/users/${userID}`, {
        method: "POST",
        body: formDataInstance,
        headers: {
          // "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      console.log("Response:", result);
      if (result) {
        refreshUserData();
        setProfileImage(result?.data?.data?.image);
      } else {
        console.error("Profile image is undefined in the response");
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setVideoPreview(null);

      // setLoading(false);
    }
  };

  const handleChangeVideoClick = () => {
    if (videoFileInputRef.current) {
      videoFileInputRef.current.click();
    } else {
      console.error("Video file input reference is null.");
    }
  };

  const [profile, setProfile] = useState(
    user?.image || "https://via.placeholder.com/150"
  );

  const fileInputRef = useRef(null);

  const handleChangeProfileClick = (e) => {
    e.stopPropagation();
    fileInputRef.current.click();
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfile(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      alert("Please upload a valid image file.");
    }
    event.target.value = "";
  };

  const handleRemoveProfileClick = (e) => {
    e.stopPropagation();
    setProfile("https://via.placeholder.com/150");
  };

  const [activeKey, setActiveKey] = useState("personalInfo");
  const [formData, setFormData] = useState({
    name: "",
    last_name: "",
    nick_name: "",
    enterprise_id: "",
    post: "",
    email: "",
    phoneNumber: "",
    bio: "",
    team_id: [],
  });

  console.log("formData", formData);
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "bio" && value.length === maxLength) {
      toast.info("Your bio can contain a maximum of 800 characters");
      return;
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectInputChange = (selectedOptions, action) => {
    if (action.name === "team_id") {
      const selectedTeams = selectedOptions
        ? selectedOptions.map((option) => option.value)
        : [];
      setFormData({
        ...formData,
        team_id: selectedTeams,
      });
      setSelectedTeams(selectedOptions);
    }
  };

  const teamOptions = teams?.map((team) => ({
    value: team.id,
    label: team.name,
  }));
  useEffect(() => {
    if (user || teams) {
      setProfile(user?.image);
      setFormData({
        name: user?.name || "",
        last_name: user?.last_name || "",
        nick_name: user?.nick_name || "",
        enterprise_id: user?.enterprise.id || "",
        post: user?.post || "",
        email: user?.email || "",
        phoneNumber: user?.phoneNumber || "",
        bio: user?.bio || "",
        team_id: user?.teams?.map((team) => team.id),
      });
      setTitle(user?.title);
      setSelectedTeams(
        user?.teams?.map((team) => ({
          value: team.id,
          label: team.name,
        }))
      );

      const userSocialLinks = user?.social_links || [];
      const userWebsites = user?.websites || [];
      const userAffiliationLinks = user?.affiliation_links || [];

      // Ensure at least one default entry is present
      //       // Parse socialLinks and websites
      // const parsedSocialLinks = JSON.parse(userSocialLinks || '[]');
      // const parsedWebsites = JSON.parse(userWebsites || '[]');

      // setSocialLinks(parsedSocialLinks);
      // setWebsites(parsedWebsites);
      setVisibility(user?.visibility);
      setSocialLinks(userSocialLinks.length > 0 ? userSocialLinks : []);
      setWebsites(userWebsites.length > 0 ? userWebsites : []);
      setAffilicationLinks(
        userAffiliationLinks.length > 0 ? userAffiliationLinks : []
      );

      setVideoPreview(user?.video);
      const userTeams = teams || [];
      setAvailableTeams(userTeams);
    }
  }, [user, teams]);

  const payload = {
    image: profile,
    name: formData.name,
    last_name: formData.last_name,
    nick_name: formData.nick_name,
    email: formData.email,
    phoneNumber: formData.phoneNumber,
    enterprise_id: formData.enterprise_id,
    bio: formData.bio,
    post: formData.post,
    social_links: socialLinks,
    websites: websites,
    video: videoPreview,
    current_password: currentPassword,
    new_password: newPassword,
    retype_password: retypePassword,
    visibility: visibility,
    title: title,
  };
  if (visibility === "team") {
    payload.team_id = selectedTeams;
  }

  const userID = parseInt(sessionStorage.getItem("user_id"));


const logoutUser = () => {
  localStorage.removeItem("isSignedIn");
  sessionStorage.clear();
  navigate("/");
};

  const handleProfile = async () => {
    try {
      setLoading(true);
      const formDataInstance = new FormData();

      // Handle profile image
      if (profile.startsWith("data:image/")) {
        const response = await fetch(profile);
        const blob = await response.blob();
        formDataInstance.append("image", blob, "profile-image.jpg");
      } else {
        formDataInstance.append("image", profile);
      }

      formDataInstance.append("title", title || "");
      formDataInstance.append("name", formData.name || "");
      formDataInstance.append("last_name", formData.last_name || "");
      // formDataInstance.append("nick_name", formData.nick_name || "");
      formDataInstance.append("email", formData.email || "");
      formDataInstance.append("phoneNumber", formData.phoneNumber || "");
      formDataInstance.append("enterprise_id", formData.enterprise_id || "");
      formDataInstance.append("bio", formData.bio || "");
      formDataInstance.append("post", formData.post || "");
      formDataInstance.append("role_id", user?.role_id || "");

      // socialLinks.forEach((link, index) => {
      //   formDataInstance.append(
      //     `social_links[${index}][platform]`,
      //     link.platform
      //   );
      //   formDataInstance.append(`social_links[${index}][link]`, link.link);
      // });

      socialLinks.forEach((link, index) => {
        if (link.id) {
          // Existing link (include ID)
          formDataInstance.append(`social_links[${index}][id]`, link.id);
        }
        formDataInstance.append(
          `social_links[${index}][platform]`,
          link.platform
        );
        formDataInstance.append(`social_links[${index}][link]`, link.link);
      });

      websites.forEach((site, index) => {
        if (site.id) {
          // Existing link (include ID)
          formDataInstance.append(`websites[${index}][id]`, site.id);
        }
        formDataInstance.append(`websites[${index}][title]`, site.title);
        formDataInstance.append(`websites[${index}][link]`, site.link);
      });

      affiliationLinks.forEach((site, index) => {
        if (site.id) {
          // Existing link (include ID)
          formDataInstance.append(`affiliation_links[${index}][id]`, site.id);
        }
        formDataInstance.append(
          `affiliation_links[${index}][title]`,
          site.title
        );
        formDataInstance.append(`affiliation_links[${index}][link]`, site.link);
      });

      // formDataInstance.append("socialLinks", JSON.stringify(socialLinks || []));
      // formDataInstance.append("websites", JSON.stringify(websites || []));

      // Handle video
      if (videoPreview?.startsWith("blob:")) {
        // If video does not exist, upload the video file
        const response = await fetch(videoPreview);
        const blob = await response.blob();
        formDataInstance.append("video", blob, "video-preview.mp4");
      } else {
        formDataInstance.append("video", videoPreview);
      }

      formDataInstance.append("current_password", currentPassword || "");
      formDataInstance.append("password", newPassword || "");
      formDataInstance.append("password_confirmation", retypePassword || "");

      formDataInstance.append("visibility", visibility || "public");
      // Conditionally append selected teams
      // if (visibility === "team") {
      selectedTeams.forEach((team) => {
        formDataInstance.append("team_id[]", team.value);
      });
      // }
      formDataInstance.append("_method", "put");

      // Conditionally append teams
      // if (visibility === "team") {
      // formDataInstance.append("team_id", JSON.stringify(selectedTeams || []));
      // const teamArray = selectedTeams || [];
      // teamArray.forEach((teamId) => {
      //   formDataInstance.append("team_id[]", teamId);
      // });
      // }

      // const teamIds = Array.isArray(formData.team_id)
      //   ? formData.team_id
      //   : formData.team_id.split(",").map((id) => parseInt(id.trim()));

      // console.log("teamIds", teamIds);
      // teamIds.forEach((teamId) => {
      //   formDataInstance.append("team_id[]", teamId);
      // });
      // formDataInstance.append("team_id", teamIds);

      for (let pair of formDataInstance.entries()) {
        console.log(pair[0] + ": " + pair[1]);
      }

      const response = await fetch(`${API_BASE_URL}/users/${userID}`, {
        method: "POST",
        body: formDataInstance,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      console.log("Response:", result);
      if (result) {
        refreshUserData();
        setProfileImage(result?.data?.data?.image);

      if (formData.email !== user.email) {
        logoutUser();
      }
      } else {
        console.error("Profile image is undefined in the response");
      }
    } catch (error) {
      console.log("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePassword = async () => {
    // Check if the password contains any blank spaces
    if (/\s/.test(newPassword)) {
      toast.error(t("profile.passwordSpacesMsg"));
      return;
    }
    // Check if the password length is at least 8 characters
    if (newPassword.length < 8) {
      toast.error(t("profile.passwordLengthMsg"));
      return;
    }
    if (newPassword !== retypePassword) {
      toast.error(t("profile.samePasswordMsg"));
      return;
    }

    try {
      const payload = {
        current_password: currentPassword,
        password: newPassword,
        password_confirmation: retypePassword,
        user_id: userID,
        _method: "put",
      };

      const response = await axios.post(
        `${API_BASE_URL}/users/password/update`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("token")}`,
          },
        }
      );
      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      navigate("/");
    } catch (error) {
      toast.error(t(`profile.${error?.response?.data?.message}`));
      console.log("Error:", error);
    }
  };

  return (
    <div className="add-profile">
      <Container fluid className="mt-4">
        {isLoading ? (
          <>
            <Spinner
              animation="border"
              role="status"
              className="center-spinner"
            ></Spinner>
          </>
        ) : (
          <Row>
            <Col
              xs={12}
              md={3}
              className="text-center mb-4 profile-img-section"
            >
              <Card
                onClick={handleChangeProfileClick}
                style={{ cursor: "pointer" }}
              >
                <Card.Img
                  variant="top"
                  src={
                    profile?.startsWith("users/")
                      ? Assets_URL + "/" + profile
                      : profile
                  }
                  className="img-fluid"
                  alt="Profile"
                />
                <Card.Body className="profile-buttons">
                  <Button
                    className="change-profile-btn"
                    onClick={handleChangeProfileClick}
                  >
                    {t("profile.buttons.changeProfile")}
                  </Button>
                  <Button
                    className="remove-profile-btn"
                    onClick={handleRemoveProfileClick}
                  >
                    {t("profile.buttons.removeProfile")}
                  </Button>
                </Card.Body>
              </Card>
              {/* Hidden file input */}
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept="image/*"
                onChange={handleImageUpload}
              />
            </Col>

            {/* Main Content */}
            <Col xs={12} md={9}>
              <Tab.Container defaultActiveKey="personalInfo">
                <Nav
                  variant="tabs"
                  className="mb-3 profile-navs"
                  activeKey={activeKey}
                  onSelect={(selectedKey) => setActiveKey(selectedKey)}
                >
                  <Nav.Item>
                    <Nav.Link
                      eventKey="personalInfo"
                      className={
                        activeKey === "personalInfo" ? "active-tab" : ""
                      }
                    >
                      <span>
                        <CgProfile size={22} />
                        {t("profile.personalInfo")}
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="socialProfiles"
                      className={
                        activeKey === "socialProfiles" ? "active-tab" : ""
                      }
                    >
                      <span>
                        <IoShareSocialOutline size={22} />
                        {t("profile.socialProfiles")}
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="changePassword"
                      className={
                        activeKey === "changePassword" ? "active-tab" : ""
                      }
                    >
                      <span>
                        <RiLockPasswordLine size={22} />
                        {t("profile.changePassword")}
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="privacy"
                      className={activeKey === "privacy" ? "active-tab" : ""}
                    >
                      <span>
                        <MdOutlinePrivacyTip size={22} />
                        {t("profile.privacy")}
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="presentation"
                      className={
                        activeKey === "presentation" ? "active-tab" : ""
                      }
                    >
                      <span>
                        <IoPlayOutline size={22} />
                        {t("profile.presentation")}
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                <Tab.Content className="profile-nav-content">
                  {/* Personal Info Tab */}
                  <Tab.Pane
                    eventKey="personalInfo"
                    className="form personal-info-form"
                  >
                    <Card>
                      <Form>
                        <h5>{t("profile.personalInfo")}</h5>
                        <small>{t("profile.personalInfoSubtext")}</small>
                        <Row className="mt-3">
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t("profile.fname")} *</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder={t("profile.fname")}
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t("profile.name")} *</Form.Label>
                              <Form.Control
                                type="text"
                                name="last_name"
                                placeholder={t("profile.name")}
                                value={formData.last_name}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t("profile.nickname")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="nick_name"
                                placeholder={t("profile.nickname")}
                                value={formData.nick_name}
                                disabled
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t("profile.company")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="enterprise_id"
                                placeholder={t("profile.company")}
                                value={user?.enterprise?.name}
                                disabled
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t("profile.job")}</Form.Label>
                              <Form.Control
                                type="text"
                                name="post"
                                placeholder="CEO"
                                value={formData.post}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t("profile.team")}</Form.Label>
                              <Select
                                className="react-select"
                                id="teamSelect"
                                isMulti
                                name="team_id"
                                isDisabled={
                                  getUserRoleID() === 4 ? true : false
                                }
                                options={
                                  Array.isArray(teamOptions) &&
                                  teams?.length > 0
                                    ? teamOptions
                                    : []
                                }
                                value={selectedTeams}
                                onChange={handleSelectInputChange}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form>
                    </Card>

                    <Card className="mt-3">
                      <Form>
                        <h5>{t("profile.contactInfo")}</h5>
                        <small>{t("profile.contactInfoSubtext")}</small>

                        <Row className="mt-3">
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>Email *</Form.Label>
                              <Form.Control
                                type="email"
                                placeholder="Email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t("profile.phone")}</Form.Label>
                              <PhoneInput
                                style={{
                                  padding: "7px 8px",
                                }}
                                className="form-control"
                                international
                                defaultCountry="FR"
                                placeholder="+33 234 345 3456"
                                value={formData.phoneNumber}
                                onChange={(value) =>
                                  setFormData({
                                    ...formData,
                                    phoneNumber: value,
                                  })
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form>
                    </Card>

                    <Card className="mt-3">
                      <Form>
                        <h5>{t("profile.bio")}</h5>
                        <small>{t("profile.bioInfoSubtext")}</small>

                        <Form.Group className="mt-3">
                          <Form.Control
                            as="textarea"
                            rows={5}
                            placeholder={t("profile.bioInfoSubtext")}
                            name="bio"
                            value={formData.bio}
                            onChange={handleChange}
                            maxLength={maxLength}
                          />
                          <div
                            className="text-start mt-1"
                            style={{ color: "#bababa", fontSize: "14px" }}
                          >
                            {formData.bio.length}/{maxLength}
                          </div>
                        </Form.Group>
                      </Form>
                    </Card>
                    {loading ? (
                      <>
                        <Button
                          variant="dark"
                          disabled
                          style={{
                            backgroundColor: "#3aa5ed",
                            border: "none",
                            padding: "10px 39px",
                          }}
                          className="mt-4"
                        >
                          <Spinner
                            as="span"
                            variant="light"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                          />
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant="primary"
                        className="mt-4 social-info-update"
                        type="submit"
                        onClick={handleProfile}
                      >
                        Save
                      </Button>
                    )}
                  </Tab.Pane>

                  {/* Social Info Tab */}
                  <Tab.Pane eventKey="socialProfiles" className="form">
                    <Card>
                      <h5>{t("profile.socialProfiles")}</h5>
                      <small>{t("profile.socialProfilesSubtext")}</small>

                      <Row className="mt-3 social-info-row">
                        <h6>{t("profile.socialMedia")}</h6>
                        {socialLinks?.map((social, index) => (
                          <Row key={index} className="mb-3 align-items-center">
                            <Col md={4}>
                              <Select
                                name="platform"
                                options={options}
                                value={options.find(
                                  (option) => option.value === social.platform
                                )}
                                onChange={(selectedOption) =>
                                  handleSelectChange(index, selectedOption)
                                }
                              />
                            </Col>
                            <Col md={6}>
                              <Form.Control
                                type="text"
                                name="link"
                                // placeholder="https://www.linkedin.com/in/mo/"
                                value={social.link}
                                onChange={(e) => handleSocialChange(index, e)}
                              />
                            </Col>
                            <Col md={2}>
                              <Button
                                onClick={() => removeSocialLink(social, index)}
                                style={{
                                  color: "#BB372F",
                                  background: "white",
                                  outline: "none",
                                  border: "none",
                                }}
                              >
                                <RiDeleteBin5Line size={22} color={"#BB372F"} />
                                &nbsp; {t("profile.remove")}
                              </Button>
                            </Col>
                          </Row>
                        ))}
                        <Button
                          className="add-social-link"
                          onClick={addSocialLink}
                        >
                          + {t("profile.add")}
                        </Button>

                        <h6 className="mt-4">Sites</h6>
                        {websites?.map((website, index) => (
                          <Row key={index} className="mb-3 align-items-center">
                            <Col md={4}>
                              <Form.Control
                                type="text"
                                name="title"
                                // placeholder="Behance"
                                value={website.title}
                                onChange={(e) => handleWebsiteChange(index, e)}
                              />
                            </Col>
                            <Col md={6}>
                              <Form.Control
                                type="text"
                                name="link"
                                // placeholder="www.behance.net/mo"
                                value={website.link}
                                onChange={(e) => handleWebsiteChange(index, e)}
                              />
                            </Col>
                            <Col md={2}>
                              <Button
                                onClick={() => removeWebsite(website, index)}
                                style={{
                                  color: "#BB372F",
                                  background: "white",
                                  outline: "none",
                                  border: "none",
                                }}
                              >
                                <RiDeleteBin5Line size={22} color={"#BB372F"} />
                                &nbsp; {t("profile.remove")}
                              </Button>
                            </Col>
                          </Row>
                        ))}
                        <Button
                          className="add-social-link"
                          onClick={addWebsite}
                        >
                          + {t("profile.add")}
                        </Button>

                        <h6 className="mt-4">Affiliation</h6>
                        {affiliationLinks?.map((affiliationLink, index) => (
                          <Row key={index} className="mb-3 align-items-center">
                            <Col md={4}>
                              <Form.Control
                                type="text"
                                name="title"
                                // placeholder="Behance"
                                value={affiliationLink.title}
                                onChange={(e) =>
                                  handleAffiliationLinkChange(index, e)
                                }
                              />
                            </Col>
                            <Col md={6}>
                              <Form.Control
                                type="text"
                                name="link"
                                // placeholder="www.behance.net/mo"
                                value={affiliationLink.link}
                                onChange={(e) =>
                                  handleAffiliationLinkChange(index, e)
                                }
                              />
                            </Col>
                            <Col md={2}>
                              <Button
                                onClick={() =>
                                  removeAffiliationLinks(affiliationLink, index)
                                }
                                style={{
                                  color: "#BB372F",
                                  background: "white",
                                  outline: "none",
                                  border: "none",
                                }}
                              >
                                <RiDeleteBin5Line size={22} color={"#BB372F"} />
                                &nbsp; {t("profile.remove")}
                              </Button>
                            </Col>
                          </Row>
                        ))}
                        <Button
                          className="add-social-link"
                          onClick={addAffiliationLinks}
                        >
                          + {t("profile.add")}
                        </Button>
                      </Row>
                    </Card>
                    {loading ? (
                      <>
                        <Button
                          variant="dark"
                          disabled
                          style={{
                            backgroundColor: "#3aa5ed",
                            border: "none",
                            padding: "10px 39px",
                          }}
                          className="mt-4"
                        >
                          <Spinner
                            as="span"
                            variant="light"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                          />
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant="primary"
                        className="mt-4 social-info-update"
                        type="submit"
                        onClick={handleProfile}
                      >
                        {t("profile.buttons.update")}
                      </Button>
                    )}
                  </Tab.Pane>

                  {/* Password Info Tab */}
                  <Tab.Pane eventKey="changePassword" className="form">
                    <Card>
                      <h5>{t("profile.changePassword")}</h5>
                      <small>{t("profile.changePasswordSubtext")}</small>

                      <Form className="mt-3">
                        <Row className="mb-3">
                          <Col md={6}>
                            <Form.Label>
                              {t("profile.currentPassword")}
                            </Form.Label>
                            <InputGroup>
                              <div
                                style={{ position: "relative", width: "100%" }}
                              >
                                <Form.Control
                                  type={
                                    showCurrentPassword ? "text" : "password"
                                  }
                                  value={currentPassword}
                                  onChange={(e) =>
                                    setCurrentPassword(e.target.value)
                                  }
                                  placeholder={t("profile.currentPassword")}
                                  style={{
                                    paddingRight: "2.5rem",
                                  }}
                                />
                                <InputGroup.Text
                                  onClick={() =>
                                    togglePasswordVisibility(
                                      setShowCurrentPassword,
                                      showCurrentPassword
                                    )
                                  }
                                  style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "transparent",
                                  }}
                                >
                                  {showCurrentPassword ? (
                                    <FaEyeSlash />
                                  ) : (
                                    <FaEye />
                                  )}
                                </InputGroup.Text>
                              </div>
                            </InputGroup>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col md={6}>
                            <Form.Label>{t("profile.newPassword")}</Form.Label>
                            <InputGroup>
                              <div
                                style={{ position: "relative", width: "100%" }}
                              >
                                <Form.Control
                                  type={showNewPassword ? "text" : "password"}
                                  value={newPassword}
                                  onChange={(e) =>
                                    setNewPassword(e.target.value)
                                  }
                                  placeholder={t("profile.newPassword")}
                                />
                                <InputGroup.Text
                                  onClick={() =>
                                    togglePasswordVisibility(
                                      setShowNewPassword,
                                      showNewPassword
                                    )
                                  }
                                  style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "transparent",
                                  }}
                                >
                                  {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                                </InputGroup.Text>
                              </div>
                            </InputGroup>
                          </Col>

                          <Col md={6}>
                            <Form.Label>
                              {t("profile.reTypePassword")}
                            </Form.Label>
                            <InputGroup>
                              <div
                                style={{ position: "relative", width: "100%" }}
                              >
                                <Form.Control
                                  type={
                                    showRetypePassword ? "text" : "password"
                                  }
                                  value={retypePassword}
                                  onChange={(e) =>
                                    setRetypePassword(e.target.value)
                                  }
                                  placeholder={t("profile.reTypePassword")}
                                />
                                <InputGroup.Text
                                  onClick={() =>
                                    togglePasswordVisibility(
                                      setShowRetypePassword,
                                      showRetypePassword
                                    )
                                  }
                                  style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    cursor: "pointer",
                                    border: "none",
                                    backgroundColor: "transparent",
                                  }}
                                >
                                  {showRetypePassword ? (
                                    <FaEyeSlash />
                                  ) : (
                                    <FaEye />
                                  )}
                                </InputGroup.Text>
                              </div>
                            </InputGroup>
                          </Col>
                        </Row>
                      </Form>
                    </Card>
                    <Button
                      className="mt-4 social-info-update"
                      type={"submit"}
                      onClick={handlePassword}
                    >
                      {t("profile.buttons.update")}
                    </Button>
                  </Tab.Pane>

                  {/* Privacy Info Tab */}
                  <Tab.Pane eventKey="privacy" className="form">
                    <Card>
                      <h5>{t("profile.privacyHeading")}</h5>
                      <small>{t("profile.privacySubtext")}</small>
                      <Form className="mt-3">
                        <Form.Group>
                          <div className="d-flex flex-column gap-3">
                            <Form.Group>
                              <Form.Check
                                type="radio"
                                label={t("profile.public")}
                                value="public"
                                checked={visibility === "public"}
                                onChange={handleVisibilityChange}
                              />
                              <Form.Text
                                className="text-muted"
                                style={{ marginLeft: "1.5rem" }}
                              >
                                {t("profile.publicSubText")}
                              </Form.Text>
                            </Form.Group>

                            <Form.Group>
                              <Form.Check
                                type="radio"
                                label={t("profile.enterprise")}
                                value="enterprise"
                                checked={visibility === "enterprise"}
                                onChange={handleVisibilityChange}
                              />
                              <Form.Text
                                className="text-muted"
                                style={{ marginLeft: "1.5rem" }}
                              >
                                {t("profile.enterpriseSubText")}
                              </Form.Text>
                            </Form.Group>

                            <Form.Group>
                              <Form.Check
                                type="radio"
                                label={t("profile.team")}
                                value="team"
                                checked={visibility === "team"}
                                onChange={handleVisibilityChange}
                              />
                              <Form.Text
                                className="text-muted"
                                style={{ marginLeft: "1.5rem" }}
                              >
                                {t("profile.TeamSubText")}
                              </Form.Text>
                            </Form.Group>
                            {visibility === "team" && (
                              <div className="mt-3 teams">
                                <DropdownButton
                                  id="team-dropdown"
                                  title={
                                    <span className="d-flex align-items-center justify-content-between">
                                      <div>
                                        <FaUserGroup className="me-2" />{" "}
                                        {t("profile.teams")}
                                      </div>
                                      <span>
                                        <GoPlus />
                                      </span>
                                    </span>
                                  }
                                  variant="outline-primary"
                                  className="mb-3 select-team"
                                  onSelect={handleTeamSelect} // Use `onSelect` here
                                >
                                  {availableTeams?.map((team, index) => {
                                    console.log(
                                      "available teams:",
                                      availableTeams
                                    );
                                    console.log(
                                      "selected teams:",
                                      selectedTeams
                                    );

                                    // Check if the current team is selected by matching the `id` with `value`
                                    const isSelected = selectedTeams?.some(
                                      (selectedTeam) =>
                                        selectedTeam.value === team.id
                                    );

                                    return (
                                      <Dropdown.Item
                                        key={index}
                                        eventKey={team?.id?.toString()} // Convert the ID to a string for Dropdown
                                        disabled={isSelected} // Disable if already selected
                                      >
                                        {team?.name}
                                      </Dropdown.Item>
                                    );
                                  })}
                                </DropdownButton>

                                <div className="all-teams">
                                  <h6>
                                    {selectedTeams?.length > 0 &&
                                      selectedTeams?.length}{" "}
                                    {t("profile.teamsAdded")}
                                  </h6>
                                  {selectedTeams.length === 0 && (
                                    <h5>{t("profile.noTeamsAdded")}</h5>
                                  )}

                                  {selectedTeams?.map((selectedTeam, index) => {
                                    console.log(
                                      "selectedTeams->",
                                      selectedTeams
                                    );
                                    console.log("selectedTeam->", selectedTeam);
                                    console.log(
                                      "availableTeams->",
                                      availableTeams
                                    );

                                    // Find the team details by ID
                                    const team = availableTeams.find(
                                      (team) => team.id === selectedTeam.value
                                    );

                                    console.log("team->", team);
                                    // Render the team details
                                    return team ? (
                                      <React.Fragment key={index}>
                                        <Row className="align-items-center mt-4">
                                          <Col xs={8}>
                                            <p className="mb-0 team-name">
                                              {index + 1}. &nbsp;
                                              <img
                                                src={
                                                  team.logo
                                                    ? Assets_URL +
                                                      "/" +
                                                      team.logo
                                                    : "/Assets/tektime.png"
                                                } // Display the team's logo or fallback to a default image
                                                width="24px"
                                                height="30px"
                                                alt=""
                                                style={{ objectFit: "contain" }}
                                                className="img-fluid"
                                              />
                                              &nbsp; {team.name}
                                            </p>
                                          </Col>
                                          <Col xs={4} className="text-end">
                                            <Button
                                              size="sm"
                                              onClick={() =>
                                                removeTeam(team.id)
                                              }
                                              style={{
                                                background: "#BB372F1A",
                                                borderRadius: "8px",
                                                border: "none",
                                                outline: "none",
                                              }}
                                            >
                                              <RiDeleteBin5Line
                                                size={22}
                                                color={"#BB372F"}
                                              />
                                            </Button>
                                          </Col>
                                        </Row>
                                        <hr></hr>
                                      </React.Fragment>
                                    ) : null;
                                  })}
                                </div>
                              </div>
                            )}
                            <Form.Group>
                              <Form.Check
                                type="radio"
                                label={t("profile.private")}
                                value="private"
                                checked={visibility === "private"}
                                onChange={handleVisibilityChange}
                              />
                              <Form.Text
                                className="text-muted"
                                style={{ marginLeft: "1.5rem" }}
                              >
                                {t("profile.privateSubText")}
                              </Form.Text>
                            </Form.Group>
                          </div>
                        </Form.Group>
                        {/* </Form> */}
                      </Form>
                    </Card>
                    {loading ? (
                      <>
                        <Button
                          variant="dark"
                          disabled
                          style={{
                            backgroundColor: "#3aa5ed",
                            border: "none",
                            padding: "10px 39px",
                          }}
                          className="mt-4"
                        >
                          <Spinner
                            as="span"
                            variant="light"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                          />
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant="primary"
                        className="mt-4 social-info-update"
                        type="submit"
                        onClick={handleProfile}
                      >
                        {t("profile.buttons.update")}
                      </Button>
                    )}
                  </Tab.Pane>

                  {/* Presentation Info Tab */}
                  <Tab.Pane eventKey="presentation" className="form">
                    <Form.Group>
                      <Form.Label style={{ fontWeight: 600 }}>
                        {t("TitleofVisitingCard")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="mb-3"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </Form.Group>
                    <Card>
                      <h5>{t("profile.presentationHeading")}</h5>
                      <small>{t("profile.presentationSubText")}</small>
                      <div className="mt-3">
                        {!videoPreview || videoPreview === "null" ? (
                          <div className="file-upload-wrapper">
                            <Form.Group
                              className="file-upload-area"
                              onDragOver={(e) => e.preventDefault()}
                              onDrop={(e) => {
                                e.preventDefault();
                                handleVideoUpload({
                                  target: { files: e.dataTransfer.files },
                                });
                              }}
                            >
                              <Form.Label
                                className="file-upload-label"
                                style={{ textAlign: "center" }}
                              >
                                {t("profile.dragFile")}

                                <br />
                                <small className="file-upload-label-format">
                                  {t("profile.fileSize")}
                                </small>
                              </Form.Label>
                              <Button
                                variant="outline-primary"
                                className="file-upload-button"
                                onClick={handleChangeVideoClick}
                              >
                                {t("profile.browseFile")}
                              </Button>
                              <Form.Control
                                ref={videoFileInputRef}
                                type="file"
                                accept="video/mp4,video/x-m4v"
                                // accept="video/mp4,video/x-m4v"
                                onChange={handleVideoUpload}
                                style={{ display: "none" }}
                              />
                            </Form.Group>
                          </div>
                        ) : (
                          <div className="text-center file-upload-wrapper">
                            <video
                              width="50%"
                              height="auto"
                              controls
                              src={
                                videoPreview?.startsWith("users/")
                                  ? Assets_URL + "/" + videoPreview
                                  : videoPreview
                              }
                            />
                            <br />
                            <Button
                              variant="danger"
                              onClick={handleVideoRemove}
                              className="mt-2 remove-presentation-btn"
                            >
                              {t("profile.buttons.removeVideo")}
                            </Button>
                            <Button
                              variant="primary"
                              onClick={handleChangeVideoClick}
                              className="mt-2 ms-2 change-presentation-btn"
                            >
                              {t("profile.buttons.changeVideo")}
                            </Button>
                            <Form.Control
                              ref={videoFileInputRef}
                              type="file"
                              accept="video/mp4,video/x-m4v"
                              // accept="video/mp4,video/x-m4v"
                              onChange={handleVideoUpload}
                              style={{ display: "none" }}
                            />
                          </div>
                        )}
                      </div>
                    </Card>
                    {loading ? (
                      <>
                        <Button
                          variant="dark"
                          disabled
                          style={{
                            backgroundColor: "#3aa5ed",
                            border: "none",
                            padding: "10px 39px",
                          }}
                          className="mt-4"
                        >
                          <Spinner
                            as="span"
                            variant="light"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            animation="border"
                          />
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant="primary"
                        className="mt-4 social-info-update"
                        type="submit"
                        onClick={handleProfile}
                      >
                        {t("profile.buttons.update")}
                      </Button>
                    )}
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default AddProfile;
